<template>
  <section ref="start">
    <div v-if="usedMedia.empty" class="pagemargin">
      <p v-if="working" class="action-working">{{ $t('media.loading') }}</p>
      <p v-if="!working">
        <i>
          {{
            $t(
              'media.' +
                (usedMedia._filter &&
                usedMedia._filter.uploadType === 'defaultUpload'
                  ? 'noDefault'
                  : 'noUser')
            )
          }}
        </i>
      </p>
    </div>
    <ul
      class="media"
      :class="{
        'media-full': ui === 'full',
        'media-list': ui === 'list',
        'media-grid': ui === 'grid',
        'media-latest': ui === 'latest',
        'media-user': ui !== 'full' && kind === 'user',
        'media-default': ui !== 'full' && kind === 'default'
      }"
    >
      <li
        :ref="'medium_' + medium.id"
        v-for="medium in usedMediaByUi"
        :key="medium.id"
        @click="go(medium)"
      >
        <h2 class="museum-typehead" v-if="museum && ui === 'latest'">
          {{ $t('media.' + medium.uploadType + 's') }}
        </h2>
        <Medium :medium="medium" :details="details" :ui="ui" />
      </li>
      <li class="flex-ghost"></li>
      <li class="flex-ghost"></li>
      <li class="flex-ghost"></li>
    </ul>
    <button
      v-if="activePaging"
      ref="next"
      @click="loadNext()"
      :disabled="!usedMedia.hasMorePages"
      :class="{ 'action-loading': loading }"
      class="button-icon paging linkcolor"
      type="button"
    >
      {{ usedMedia.currentPage }}
      <svg v-if="activePaging" class="text-icon">
        <use xlink:href="@/assets/symbols.svg#chevron-down" />
      </svg>
    </button>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import util from '@/util'

import Medium from '@/components/Medium.vue'

export default {
  name: 'MediaList',
  components: {
    Medium
  },
  // media: pagedmedia
  // ui: details|list|grid[default]
  // user: for user page
  // details: home, mediumonly, place, details
  // kind: user|default
  props: ['media', 'ui', 'details', 'kind', 'linkmain', 'mediumid'],
  data() {
    return {
      observer: null,
      loading: false
    }
  },
  computed: Object.assign(mapState(['working', 'museum', 'museumFull']), {
    activePaging() {
      return (
        this.usedMedia.hasMorePages &&
        !['home', 'place', 'latlng'].includes(this.$route.name)
      )
    },
    usedMedia() {
      return this.media
    },
    usedMediaByUi() {
      return this.usedMedia[
        ['full', 'grid'].includes(this.ui) ? 'media' : 'previews'
      ]
    }
  }),
  watch: {
    mediumid() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.scrollTo()
        }, 200)
      })
    },
    museumFull() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.scrollTo()
        }, 200)
      })
    }
  },
  methods: {
    scrollTo() {
      const id = this.mediumid || this.museumFull?.id
      let $medium = this.$refs['medium_' + id]
      if ($medium?.length) {
        setTimeout(() => {
          $medium[0]?.scrollIntoView({ behavior: 'smooth' })
        }, 0)
      } else {
        util.toTop()
      }
    },
    nextVisible(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.loadNext()
        }
      })
    },
    loadNext() {
      if (this.usedMedia.hasMorePages) {
        let $scroller = document.getElementById('main')
        let before = $scroller.scrollTop
        this.loading = true
        this.usedMedia
          .next()
          .then(() => {
            $scroller.scrollTop = before
          })
          .finally(() => (this.loading = false))
      }
      // else {
      //   this.$refs.next && this.observer?.unobserve(this.$refs.next)
      // }
    },
    go(medium) {
      if (this.museum) {
        this.$store.commit('museumFull', medium)
      } else if (this.linkmain) {
        let params = Object.assign({}, this.linkmain.params)
        params.mediumid = medium.id
        if (params?.type === 'FROM_MEDIUM_PLUS_s') {
          // placeTeaser has mixed list!
          params.type = medium.uploadType + 's'
        }
        if (params?.area === 'MEDIUM_PLACE') {
          // home links
          params.area = medium.placeId ? 'place' : 'latlng'
          params.id = medium.placeId
        }

        this.$router.push({
          name: this.linkmain.name,
          params: params
        })
      }
    },
    init() {
      this.usedMedia.currentPage = 1
      this.scrollTo()
      if (!this.observer) {
        this.observer = new IntersectionObserver(this.nextVisible, {
          root: document.querySelector('main'),
          rootMargin: '100px',
          threshold: 1.0
        })
      }
      if (this.$refs.next) {
        this.observer.observe(this.$refs.next)
      }
    }
  },
  mounted() {
    this.init()
  },
  activated() {
    this.init()
  },
  deactivated() {
    this.$refs.next && this.observer?.unobserve(this.$refs.next)
  }
}
</script>

<style>
.media {
  display: flex;
  list-style: none;
  padding: 0 calc(var(--pagemargin) / 2) 0;
  margin-bottom: var(--sectionend);
}
.media figure {
  margin: 0;
}

.museum-typehead {
  text-align: center;
}

.media-full {
  flex-wrap: wrap;
  padding: 0;
}
.media-full > * {
  flex: 1 1 100%;
}
/* .media-full > li:not(.flex-ghost) {
  min-height: calc(var(--height) * 0.75);
} */
.media-full > * + * {
  padding-top: 0.5em;
}

.media-full figure {
  padding: 0;
}

.media-latest {
  cursor: pointer;
  flex-wrap: wrap;
}
.media-latest > * {
  flex: 0 0 100%;
}
@media (min-width: 22em) {
  .media-latest > * {
    flex: 1 1 50%;
  }
}
.media-latest .media-img {
  width: 100%;
  height: 100%;
  max-height: 60vh;
  object-fit: cover;
  aspect-ratio: 3/2;
}

.media-grid {
  cursor: pointer;
  flex-wrap: wrap;
}
.media-grid > * {
  flex: 1 1 50%;
}
.media-grid .media-img {
  margin-bottom: 0.5em;
}

.media-list {
  overflow: auto;
  margin-bottom: calc(var(--sectionend) / 2);
}
.media-list .media-img {
  cursor: pointer;
  object-fit: cover;
  min-width: 15em;
  width: calc(100vw / 3 * 3 / 3);
  max-width: none;
  min-height: 10em;
  height: calc(100vw / 3 * 2.2 / 3);
  max-height: 50vh;
  aspect-ratio: 3/2;
}

.media-default .medium {
  border-radius: var(--radius);
}
.media-default .media-img,
.media-default video {
  filter: saturate(0.75) sepia(0.75) contrast(0.75);
}
.media-user .media-img,
.media-user video {
  filter: saturate(1.25);
  border-radius: 0;
}

button.paging {
  display: block;
  margin: 0 auto;
}
button.paging svg {
  margin: 0 auto;
}
@media (min-width: 60em) {
  .media-grid > * {
    flex: 1 1 33.333%;
  }
}
@media (min-width: 100em) {
  .media-grid > * {
    flex: 1 1 25%;
  }
}
</style>
