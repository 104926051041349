<template>
  <div class="placesselect">
    <select ref="choices" placeholder="$t('places.placeholder')"></select>
    <button
      v-if="place"
      type="button"
      @click.stop="reset"
      class="button-icon button-small"
    >
      <svg class="text-icon">
        <use href="@/assets/symbols.svg#x" />
      </svg>
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Choices from 'choices.js'
import 'choices.js/public/assets/styles/choices.min.css'

export default {
  name: 'PlacesSelect',
  computed: mapState(['places', 'place']),
  data: function () {
    return {
      $choices: null,
      article: null,
      measure: null
    }
  },
  watch: {
    places() {
      this.setChoices()
    },
    place(place) {
      if (place) {
        this.$choices?.setValue([
          {
            value: place.id,
            label: place.title
          }
        ])
      } else {
        this.$choices.setChoiceByValue('')
      }
    }
  },
  methods: {
    setChoices() {
      if (this.$choices) {
        let choices = this.places
          .map((place) => {
            return {
              label: place.title,
              value: place.id,
              selected: this.place && this.place.id === place.id
            }
          })
          .sort(function (a, b) {
            if (a.label === b.label) {
              return 0
            }
            return a.label > b.label ? 1 : -1
          })
        choices.unshift({
          label: this.$t('places.placeholder'),
          value: '',
          selected: !this.place
        })
        this.$choices.clearChoices()
        this.$choices.setChoices(choices, 'value', 'label', true)
      }
    },
    setPlace(id) {
      this.$store.dispatch('selectPlace', id)
    },
    reset() {
      this.$store.commit('place', null)
    }
  },
  mounted: function () {
    let $select = this.$refs.choices

    if ($select) {
      this.$choices = new Choices($select, {
        allowHTML: false,
        shouldSort: false,
        addItemText: this.$t('places.placeholder'),
        // placeholder: true,
        // placeholderValue: this.$t('places.placeholder'),
        searchPlaceholderValue: this.$t('places.search'),
        searchFields: ['label'],
        noChoicesText: this.$t('places.noChoicesText'),
        noResultsText: this.$t('places.noResultsText'),
        itemSelectText: this.$t('places.itemSelectText')
      })
      $select.addEventListener('choice', (e) => {
        this.setPlace(e.detail.value)
      })
      this.setChoices()
    }
  }
}
</script>

<style>
.placesselect {
  /* min-width: 25vw; */
  display: flex;
  align-items: center;
}
.placesselect .choices {
  flex-grow: 1;
  margin: 0;
}
.placesselect .choices .choices__inner {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 0;
}
.placesselect .choices__list--dropdown,
.placesselect .choices__list--dropdown .choices__list {
  max-height: 25vh;
}
.placesselect .choices__list--dropdown .choices__item--selectable {
  padding-right: 1.5em;
}
</style>
