import axios from 'axios'
import device from '@/device'
import i18n from '@/i18n'
import store from '@/store'
import models from '@/models'
import { notify } from '@kyvg/vue3-notification'

const LOCAL = 0

const API_HOST = 'https://archiv.historisches-museum-bielefeld.de/api/'
const API_LOCAL = location.origin + '/static/apidata/'
const EXT = '.json'

var cancelRequests = {}

// QUEUE
function Queue() {
  this._methods = {
    get: _get,
    put: _put,
    post: _post,
    delete: _delete
  }
  this._queue = []
  this.load()
}
Queue.prototype.load = function () {
  return device
    .load('queue', [])
    .then((queue) => {
      this._queue = queue
      return Promise.resolve(queue.length)
    })
    .catch((e) => Promise.reject(e))
}
Queue.prototype.save = function (noMessage) {
  return device
    .save('queue', this._queue)
    .then(() => {
      if (!noMessage) {
        notify({
          title: i18n.global.t('sync.savedoffline'),
          type: 'notification-minor',
          ignoreDuplicates: true
        })
      }
      return Promise.resolve()
    })
    .catch((e) => {
      store.state.DEBUG && console.log('Queue.save', e)
      return Promise.reject(e)
    })
}
Queue.prototype.add = function (func, params) {
  this._queue.push({
    func,
    params
  })
  return this.save()
}
Queue.prototype.sync = function () {
  if (this._queue.length === 0) {
    return
  }
  if (!store.state.onLine) {
    notify({
      title: i18n.global.t('sync.offline')
    })
    return
  }
  var ok = []
  var failed = []
  var promises = []
  this._queue.forEach((item) => {
    promises.push(
      this._methods[item.func](...item.params)
        .then(() => {
          ok.push(item)
          return Promise.resolve()
        })
        .catch((e) => {
          store.state.DEBUG && console.log(e, item)
          failed.push(item)
          return Promise.reject()
        })
    )
  })
  this._queue.length = 0
  Promise.allSettled(promises)
    .then(() => {
      let message =
        failed.length === 0
          ? i18n.global.tc('sync.synced.ok', ok.length)
          : i18n.global.tc('sync.synced.fail', failed.length)
      if (ok.length > 0 && failed.length > 0) {
        message = i18n.global.t('sync.synced.some', {
          ok: ok.length,
          fail: failed.length
        })
      }
      notify({
        title: message,
        type: failed.length ? 'notification-major' : 'notification-minor',
        duration: failed.length ? -1 : 5000
      })
      if (failed.length) {
        if (
          confirm(i18n.global.t('sync.keepfailed', { fail: failed.length }))
        ) {
          this._queue = failed
        }
      }
      this.save(true)
    })
    .catch((e) =>
      notify({
        title: i18n.global.t('sync.failed', { e: e }),
        type: 'notification-major',
        duration: -1
      })
    )
}

var _syncQueue = new Queue()

function syncQueue() {
  _syncQueue.sync()
  return Promise.resolve()
}

function loadQueue() {
  _syncQueue
    .load()
    .then((anyItems) => (anyItems ? syncQueue() : null))
    .catch((e) => store.state.DEBUG && console.log(e))
}

function _send(method, path, options = {}) {
  if (store.state.loading && !options.ignoreLoading && !options.parallel) {
    return Promise.reject({ status: 0, message: 'LOADING' })
  }
  // force request even if app is still loading
  delete options.ignoreLoading

  let url = LOCAL || options.LOCAL ? API_LOCAL + path + EXT : API_HOST + path

  if (options.NO_API && !LOCAL) {
    url = url.replace('/api', '')
  }

  let config = {
    method: method,
    url: url,
    withCredentials: !options.noCredentials,
    headers: options.headers || {},
    cancelToken: new axios.CancelToken(function executor(cancel) {
      // An executor function receives a cancel function as a parameter
      cancelRequests[path] = cancel
    })
  }

  if (options.data) {
    config.data = options.data
  } else if (options.params) {
    config.params = options.params
  }

  if (store.state.onLine) {
    if (!options.parallel) {
      store.commit('loading', true)
    }
    return axios(config)
      .then(function (response) {
        if (!options.parallel) {
          store.commit('loading', false)
        }
        return Promise.resolve(response.data)
      })
      .catch(function (e) {
        store.state.DEBUG && console.log('AXIOS', config, e.response)

        if (!options.parallel) {
          store.commit('loading', false)
        }
        if (
          !options.ignoreErrors &&
          [401, 403].includes(e.response?.status) &&
          store.state.user
        ) {
          store.dispatch('logout')
          return Promise.reject({
            status: e.response?.status,
            message: i18n.global.t('loggedout')
          })
        } else {
          const data = e?.response?.data
          let formErrors = []
          Object.entries(data?.form?.errors?.children || {}).forEach(
            (entry) => {
              let errors = Object.values(entry?.[1]?.errors || {})
              if (errors.length) {
                formErrors = formErrors.concat(errors)
              }
            }
          )
          let formMessages = formErrors.length ? formErrors.join(', ') : false
          return Promise.reject({
            status: e?.response?.code || e?.response?.status || '',
            message:
              formMessages ||
              data?.error ||
              (data?.errors?.length ? data?.errors?.join(', ') : false) ||
              data?.message ||
              e,
            data: data
          })
        }
      })
  } else {
    return Promise.reject({
      status: 0,
      message: i18n.global.t('offline'),
      offline: true
    })
  }
}
function _get(path, options = {}) {
  // if not explicit ignoreLoading=false do ignore!
  return _send('get', path, {
    params: options.params,
    ignoreLoading: !(options.ignoreLoading === false),
    parallel: options.parallel
  })
}
function _put(path, data = null) {
  if (store.state.onLine) {
    return _send('put', path, { data: data }, { ignoreLoading: true })
  } else {
    return _syncQueue.add('put', [path, data])
  }
}
function _post(path, id, data = null, options = {}) {
  if (store.state.onLine) {
    return _send('post', path + (id || id === 0 ? '/' + id : ''), {
      NO_API: options.NO_API,
      data: data,
      headers: options.headers,
      ignoreLoading: !(options.ignoreLoading === false),
      ignoreErrors: options.ignoreErrors
    })
  } else {
    return _syncQueue.add('post', [path, id, data, options])
  }
}
function _delete(path, id) {
  if (store.state.onLine) {
    return _send('delete', path + (id === 0 || id > 0 ? '/' + id : ''), {
      ignoreLoading: true
    })
  } else {
    return _syncQueue.add('delete', [path, id])
  }
}
// function _cancelRequest(path) {
//   if (cancelRequests[path]) {
//     cancelRequests[path]('CANCEL')
//     delete cancelRequests[path]
//   }
// }

function _dataToFormData(data) {
  let formData = new FormData()
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key])
  })
  return formData
}

function register(data) {
  return _post('user/register', null, _dataToFormData(data), {
    noCredentials: true
  })
}
function login(data) {
  // json!
  return _post('user/login', null, data, {
    noCredentials: true
  })
    .then((r) => Promise.resolve(r))
    .catch((e) => Promise.reject(e))
}
function change(token, formData) {
  return _post('user/reset-password/reset', token, formData)
}
function resetPassword(formData) {
  return LOCAL
    ? _get('user/reset-password')
    : _post('user/reset-password', null, formData)
}
function logout() {
  return LOCAL
    ? _get('logout')
    : _post('logout', null, null, { NO_API: true, ignoreErrors: true })
}

function loadProfile() {
  return _get('user/profile')
}

function loadDashboard() {
  return _get('dashboard')
    .then((data) => {
      let server = [data.server.scheme, '://', data.server.hostname, '/'].join(
        ''
      )
      data.mediaServer = [server, data.server.mediaDir, '/'].join('')
      // data.newsServer = [server, data.server.newsMediaPreviewDir, '/'].join('')
      data.placePreviewServer = [
        server,
        data.server.placeMediaPreviewDir,
        '/'
      ].join('')
      return Promise.resolve(data)
    })
    .catch((e) => Promise.reject(e))
}

function loadPlaces() {
  return _get('places')
}
function loadPlace(id) {
  return _get('places/' + id)
}

function loadMedia(page = 1, filter = {}) {
  const EXTRA = ['filter_lat', 'filter_lng', 'filter_distance']
  let params = { filter: {} }
  Object.keys(filter)
    .filter((key) => !EXTRA.includes(key))
    .forEach((key) => {
      params.filter[key] = filter[key]
    })
  EXTRA.forEach((extra) => {
    let value = filter[extra]
    if (value) {
      if (extra === 'filter_distance') {
        value = parseFloat(value) * 0.001
      }
      params[extra] = value
    }
  })
  return _get('media/page/' + page, {
    params
  })
    .then((r) => {
      r.media = r.media.map((media) => new models.Medium(media))
      return Promise.resolve(r)
    })
    .catch((e) => Promise.reject(e))
}

function uploadMedia(formData) {
  return _post('media/add', null, formData, {
    'Content-Type': 'multipart/form-data'
  })
}

function addRating(mediumId, rating) {
  return _post(
    'ratings/media/' + mediumId + '/add',
    null,
    _dataToFormData({
      score: rating
    })
  )
}

// function get3Words(position) {
//   const KEY = 'V48RJAPG'
//   return axios({
//     method: 'get',
//     url: 'https://api.what3words.com/v3/convert-to-3wa',
//     params: {
//       key: KEY,
//       coordinates: position.join(','),
//       language: 'de',
//       format: 'json'
//     }
//   })
//     .then((res) => {
//       return Promise.resolve(res?.data.words)
//     })
//     .catch((e) => {
//       console.log(e)
//     })
// }

export default {
  loadQueue,
  syncQueue,

  register,
  login,
  change,
  resetPassword,
  logout,
  loadProfile,
  loadDashboard,
  loadPlaces,
  loadPlace,
  loadMedia,
  uploadMedia,
  addRating
  // get3Words
}
