<template>
  <div>
    <component
      v-if="mediumPlace"
      :is="linked && !onMuseum ? 'StopAction' : 'StopComponent'"
      :place="mediumPlace"
    />
    <component
      v-else
      :is="linked && !onMuseum ? 'LatLngAction' : 'LatLng'"
      :latlng="[medium.lat, medium.lng]"
      :medium="medium"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import StopComponent from '@/components/Stop.vue'
import StopAction from '@/components/StopAction.vue'
import LatLng from '@/components/LatLng.vue'
import LatLngAction from '@/components/LatLngAction.vue'

export default {
  name: 'MediumLocation',
  props: ['medium', 'linked'],
  components: {
    StopComponent,
    StopAction,
    LatLng,
    LatLngAction
  },
  computed: Object.assign(
    mapState({
      ...mapGetters(['getPlaceById'])
    }),
    {
      mediumPlace() {
        return this.getPlaceById(this.medium.placeId)
      },
      onMuseum() {
        return this.$route.name === 'museum'
      }
    }
  )
}
</script>
