import { notify } from '@kyvg/vue3-notification'
import { Capacitor } from '@capacitor/core'
import store from '@/store/index.js'
import svgPanZoom from 'svg-pan-zoom'
import '@/assets/lib/hammer.min.js'

function getPlatform() {
  return Capacitor.getPlatform()
}

function notifyErrors(e, type) {
  let titles = []
  if (typeof e === 'string') {
    titles.push(e)
  } else if (e) {
    if (e.message) {
      titles.push(e.message)
    } else if (e.data) {
      if (e.data.errors) {
        e.data.errors.forEach((error) => titles.push(error.message))
      } else {
        Object.keys(e.data).forEach((k) => {
          let data =
            e.data[k] instanceof Array ? e.data[k].join('<br>') : e.data[k]
          titles.push(k === 'exception' ? e.data.exception[0].message : data)
        })
      }
    }
  }
  if (titles.length) {
    notify({
      title: titles.join('<br>'),
      type: 'notification-' + type
    })
  }
}

function debounce(fn, delay = 200) {
  return (function (f, d) {
    let timer
    return function (...args) {
      timer && clearTimeout(timer)
      timer = setTimeout(() => {
        f(...args)
      }, d)
    }
  })(fn, delay)
}

function initPanZoom($el, settings) {
  const museum = store.state.museum
  let hammer = null
  let eventsHandler = {
    haltEventListeners: [
      'touchstart',
      'touchend',
      'touchmove',
      'touchleave',
      'touchcancel'
    ],
    init: (options) => {
      var instance = options.instance,
        initialScale = 1,
        pannedX = 0,
        pannedY = 0
      hammer = window.Hammer(options.svgElement, {
        inputClass:
          window.Hammer[
            window.Hammer.SUPPORT_POINTER_EVENTS
              ? 'PointerEventInput'
              : 'TouchInput'
          ]
      })
      hammer.on('tap', (e) => {
        console.log(e)
        let $target = e.target
        while ($target.parentElement) {
          if ($target.tagName.toLowerCase() === 'svg') {
            break
          } else if (settings.checkTarget($target)) {
            break
          }
          $target = $target.parentElement
        }
      })
      if (!museum) {
        hammer.get('pinch').set({ enable: true })
        hammer.on('panstart panmove', function (e) {
          // On pan start reset panned variables
          if (e.type === 'panstart') {
            pannedX = 0
            pannedY = 0
          }
          instance.panBy({
            x: e.deltaX - pannedX,
            y: e.deltaY - pannedY
          })
          pannedX = e.deltaX
          pannedY = e.deltaY
        })
        hammer.on('pinchstart pinchmove', function (e) {
          // On pinch start remember initial zoom
          if (e.type === 'pinchstart') {
            initialScale = instance.getZoom()
            instance.zoomAtPoint(initialScale * e.scale, {
              x: e.center.x,
              y: e.center.y
            })
          }
          instance.zoomAtPoint(initialScale * e.scale, {
            x: e.center.x,
            y: e.center.y
          })
        })
      }
      // Prevent moving the page on some devices when panning over SVG
      options.svgElement.addEventListener('touchmove', function (e) {
        e.preventDefault()
      })
    },
    destroy: function () {
      hammer?.destroy()
    }
  }
  return svgPanZoom($el.querySelector('svg'), {
    customEventsHandler: eventsHandler,
    center: true,
    fit: true,
    mouseWheelZoomEnabled: true,
    preventMouseEventsDefault: true,
    dblClickZoomEnabled: false,
    minZoom: 1,
    maxZoom: 3.375,
    contain: true,
    zoomEnabled: !museum,
    panEnabled: !museum,
    beforePan: function (oldPan, newPan) {
      let limitX = window.innerWidth / 1.5
      let limitY = window.innerHeight / 1.5
      let sizes = this.getSizes()
      let leftLimit =
        -((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) + limitX
      let rightLimit = sizes.width - limitX - sizes.viewBox.x * sizes.realZoom
      let topLimit =
        -((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) + limitY
      let bottomLimit = sizes.height - limitY - sizes.viewBox.y * sizes.realZoom
      return {
        x: Math.max(leftLimit, Math.min(rightLimit, newPan.x)),
        y: Math.max(topLimit, Math.min(bottomLimit, newPan.y))
      }
    }
  })
}

function toTop() {
  document.getElementById('main').scrollTop = 0
}

function shortLatLng(latlng) {
  return [latlng.lat.toFixed(4), latlng.lng.toFixed(4)]
}

export { getPlatform, notifyErrors, debounce, initPanZoom, toTop, shortLatLng }

export default {
  getPlatform,
  notifyErrors,
  debounce,
  toTop,
  shortLatLng
}
